import React from "react"
import style from "./search.mod.scss"
import Block from "@global/layout/Block"
import SearchWhiteSvg from "@svgs/search-white.svg"

const Search = ({ id, placeholder = "", onChange = () => {}, value }) => {
  return (
    <Block padding="top" gutters={true}>
      <div className={style.search}>
        <input
          id={id}
          type="search"
          className={style.search__input}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e)}
        />
        <button className={style.search__button}>
          <SearchWhiteSvg />
        </button>
        {/*
        TODO: Add back in once advanced filtering is complete
        <div className={style.search__more}>
          <MoreSvg />
        </div> */}
      </div>
    </Block>
  )
}

export default Search
