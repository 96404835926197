import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import { useAuth0 } from "@utils/auth"
import Cards from "@global/cards/CardsContainer"
import Carousel from "@elements/Carousel"
import Seo from "@elements/Seo"
import Card from "@global/cards/Card"
import Search from "@elements/Search"
import Cta from "@global/cards/Cta"
import Block from "@global/layout/Block"
import { recipeCategoryConfig, dealCtaConfig } from "@helpers/listHelper"
import { navigate } from "gatsby"
import SwiperCore, { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import FeaturedFavourites from "../components/homepage/FeaturedFavourites"
import LatestRecipes from "../components/homepage/LatestRecipes"

SwiperCore.use([Pagination])

const Recipes = ({ data, pageContext: { title, parent } }) => {
  const {
    allRecipes,
    newRecipes,
    breakfasts,
    snacks,
    lightMeals,
    mainMeals,
    mealTypes,
    dietaryTypes,
    recipeDeals
  } = data

  const { favouriteRecipes } = useAuth0()

  const favRecipeIds = favouriteRecipes

  const allBreakfasts = breakfasts.nodes
  const favBreakfasts = allBreakfasts.filter(breakfast => {
    return favRecipeIds.indexOf(breakfast.originalId) !== -1
  })

  const allLightMeals = lightMeals.nodes
  const favLightMeals = allLightMeals.filter(lightMeal => {
    return favRecipeIds.indexOf(lightMeal.originalId) !== -1
  })

  const allSnacks = snacks.nodes
  const favSnacks = allSnacks.filter(snack => {
    return favRecipeIds.indexOf(snack.originalId) !== -1
  })

  const allMainMeals = mainMeals.nodes
  const favMainMeals = allMainMeals.filter(mainMeal => {
    return favRecipeIds.indexOf(mainMeal.originalId) !== -1
  })

  const filterContent = e => {
    const query = e.target[0].value
    routeToRecipes(query)
  }

  const routeToRecipes = query => {
    navigate("/recipes/all", {
      state: { query }
    })
  }

  const categories = [
    [
      {
        id: "all-DatoCmsRecipe",
        path: "/recipes/all",
        name: "All Recipes",
        image: allRecipes.nodes[0].heroImage
      }
    ],
    mealTypes.nodes,
    dietaryTypes.nodes
  ]

  return (
    <>
      <Layout title={title} parent={parent}>
        <Seo
          title={data.contentYaml.recipes.meta.title}
          description={data.contentYaml.recipes.meta.desc}
        />

        <form
          onSubmit={e => {
            e.preventDefault()
            filterContent(e)
          }}
        >
          <Search id="recipesSearch" placeholder="Search recipes..." />
        </form>

        <LatestRecipes data={newRecipes.nodes.slice(0, 2)} />
        <FeaturedFavourites
          tabNames={["breakfast", "light meal", "snack", "main meal"]}
          breakfasts={favBreakfasts}
          snacks={favSnacks}
          mains={favMainMeals}
          lights={favLightMeals}
        />
        <RecipeCategories categories={categories} />
        {recipeDeals.length > 0 && <RecipeDeals deals={recipeDeals} />}
      </Layout>
    </>
  )
}

export const RecipeDeals = ({ deals }) => {
  return (
    <>
      {deals && (
        <Block padding="top">
          <Carousel heading="deals" buttonPath={"/deals"} buttonText="View all">
            <Swiper
              spaceBetween={12}
              slidesPerView="auto"
              initialSlide={1}
              centeredSlides={true}
              preventClicks={true}
              loop={true}
              className="swiper-container--3-col-for-medium"
            >
              {deals.nodes.map(deal => (
                <SwiperSlide key={deal.id} className="swiper-slide--deal">
                  <Cta {...dealCtaConfig(deal)} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Carousel>
        </Block>
      )}
    </>
  )
}

export const RecipeCategories = ({ categories }) => {
  return (
    <Block padding="both" background="primary-tint">
      <Cards
        orientation="horizontal"
        heading="recipes"
        buttonPath={"/recipes/all"}
        buttonText="View all"
      >
        {categories &&
          categories
            .flat()
            .map(category => (
              <Card
                {...recipeCategoryConfig(category)}
                modifier="simple"
                key={`categories-${category.id}`}
                elevated={true}
              />
            ))}
      </Cards>
    </Block>
  )
}

export default Recipes

export const query = graphql`
  query RecipesQuery($language: String, $locale: String) {
    allRecipes: allDatoCmsRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 1
    ) {
      nodes {
        heroImage {
          gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
        }
      }
    }

    newRecipes: allDatoCmsRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
    ) {
      nodes {
        ...RecipeCard
      }
    }
    breakfasts: allDatoCmsRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { mealTypes: { elemMatch: { name: { eq: "Breakfast" } } } }
    ) {
      nodes {
        ...RecipeCard
      }
    }
    lightMeals: allDatoCmsRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { mealTypes: { elemMatch: { name: { eq: "Light Meal" } } } }
    ) {
      nodes {
        ...RecipeCard
      }
    }
    snacks: allDatoCmsRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { mealTypes: { elemMatch: { name: { eq: "Snack" } } } }
    ) {
      nodes {
        ...RecipeCard
      }
    }
    mainMeals: allDatoCmsRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { mealTypes: { elemMatch: { name: { eq: "Main Meal" } } } }
    ) {
      nodes {
        ...RecipeCard
      }
    }

    mealTypes: allDatoCmsMealType {
      nodes {
        id
        path
        name
        namePlural
        image {
          gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
        }
      }
    }
    dietaryTypes: allDatoCmsDietaryType {
      nodes {
        id
        path
        name
        image {
          gatsbyImageData(imgixParams: { fit: "crop", w: "740", h: "532" })
        }
      }
    }
    recipeDeals: allDatoCmsDeal(
      filter: {
        locale: { eq: $language }
        country: { in: [$locale, "Both"] }
        tags: { elemMatch: { slug: { eq: "recipe-deal" } } }
      }
    ) {
      nodes {
        title
        id
        slug
        path
        code
        image {
          gatsbyImageData(imgixParams: { fit: "crop", w: "664", h: "400" })
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }

    contentYaml {
      recipes {
        meta {
          title
          desc
        }
      }
    }
  }
`
