import React from "react"
import style from "./carousel.mod.scss"
import Button from "@elements/Button"
import PropTypes from "prop-types"

const Carousel = ({
  children,
  heading,
  buttonPath,
  buttonText,
  lead,
  modifier
}) => {
  const classes = [style.carousel]
  modifier && classes.push(`${style.carousel}--${modifier}`)

  return (
    <div className={[style.carousel, style["carousel--" + modifier]].join(" ")}>
      {(heading || (buttonPath && buttonText) || lead) && (
        <div className={style.carousel__header}>
          {heading && <h2 className={style.carousel__heading}>{heading}</h2>}
          {buttonPath && buttonText && (
            <div className={style.carousel__button}>
              <Button to={buttonPath} text={buttonText} size="small" />
            </div>
          )}
          {lead && (
            <div className={style.carousel__lead}>
              <p>{lead}</p>
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export default Carousel

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  lead: PropTypes.string,
  buttonPath: PropTypes.string,
  buttonText: PropTypes.string,
  modifier: PropTypes.string
}
